import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import {
  BillingProductValidationSchemaType,
  BillingServiceValidationSchemaType,
} from '@lupa/utils/validators/invoiceValidators';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { v4 as uuidv4 } from 'uuid';

export type BillingItem =
  | BillingProductValidationSchemaType
  | BillingServiceValidationSchemaType;

export const getBillingInfoFromSearchedService = (
  service: Nullable<TrpcRouterOutputs['store']['searchServices'][0]>,
): BillingServiceValidationSchemaType => {
  if (service == null) {
    return {
      id: uuidv4(),
      name: '',
      quantity: 1,
      unit_price: 0,
      price: 0,
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      vat_percentage: globalSingleton.currentStore.vat_percentage,
      created_at: new Date().toISOString(),
    };
  }

  return {
    id: uuidv4(),
    service_id: service.id,
    name: service.name,
    quantity: 1,
    unit_price: service.price,
    price: service.price,
    discount: 0,
    discount_type: DISCOUNT_TYPE.PERCENTAGE,
    service_detail: { category: service.category },
    vat_percentage: globalSingleton.currentStore.vat_percentage,
    created_at: new Date().toISOString(),
  };
};

export type ProductForBillingProduct = Pick<
  TrpcRouterOutputs['products']['searchProducts'][0],
  | 'id'
  | 'name'
  | 'price'
  | 'vat_percentage'
  | 'has_batches'
  | 'has_subunit'
  | 'measure_unit'
  | 'subunit'
  | 'subunit_multiplier'
  | 'unit'
  | 'category'
  | 'dispensing_fee'
>;

export const getBillingInfoFromSearchedProduct = (
  product: Nullable<ProductForBillingProduct>,
): BillingProductValidationSchemaType => {
  if (product == null) {
    // Generate a new billing product with a random UUID
    return {
      id: uuidv4(),
      name: '',
      quantity: 1,
      unit_price: 0,
      price: 0,
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      vat_percentage: globalSingleton.currentStore.vat_percentage,
      dispensing_fee: 0,
      created_at: new Date().toISOString(),
    };
  }

  const billingProduct: BillingProductValidationSchemaType = {
    id: uuidv4(),
    product_id: product.id,
    name: product.name,
    quantity: 1,
    price: product.price,
    unit_price: product.price,
    vat_percentage: product.vat_percentage,
    discount: 0,
    discount_type: DISCOUNT_TYPE.PERCENTAGE,
    batches: product.has_batches ? [] : undefined,
    has_subunit: false,
    subunit_multiplier: null,
    measure_unit: null,
    unit: null,
    product_detail: { category: product.category },
    subunit: null,
    dispensing_fee: product.dispensing_fee,
    created_at: new Date().toISOString(),
  };

  if (product.has_subunit) {
    const subunitMultiplier =
      product.measure_unit === product.subunit && product.subunit_multiplier
        ? 1 / product.subunit_multiplier
        : 1;

    billingProduct.unit_price *= subunitMultiplier;
    billingProduct.price *= subunitMultiplier;
    billingProduct.has_subunit = true;
    billingProduct.subunit_multiplier = product.subunit_multiplier;
    billingProduct.measure_unit = product.measure_unit;
    billingProduct.unit = product.unit;
    billingProduct.subunit = product.subunit;
  }

  return billingProduct;
};
