export enum ENVIRONMENT_TYPE {
  TEST = 'test',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum APPOINTMENT_STATUS {
  CANCELLED = 'cancelled',
  CHECKED_IN = 'checked_in',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  IN_PROGRESS = 'in_progress',
  NO_SHOW = 'no_show',
  PENDING = 'pending',
  READY_FOR_CHECKOUT = 'ready_for_checkout',
  REQUESTED = 'requested',
}

export enum ORDER_STATUS {
  // TODO: Add more statuses.
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  PENDING = 'pending',
}

export enum ORDER_ITEM_STATUS {
  // TODO: Add more statuses.
  PENDING = 'pending',
  RECEIVED = 'received',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
}

export enum EMPLOYEE_STATUS {
  ACTIVE = 'active',
  CONFIRMED = 'confirmed',
  DELETED = 'deleted',
  ON_HOLIDAYS = 'on_holidays',
  PENDING = 'pending',
  PENDING_VERIFICATION = 'pending_verification',
  SICK = 'sick',
  WORKING = 'working',
}

export enum APPOINTMENT_PAYMENT_STATUS {
  DEPOSIT_ON_HOLD = 'deposit_on_hold',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  PENDING = 'pending',
}

export enum APPOINTMENT_PRE_PAYMENT_TYPE {
  ADVANCE_PAYMENT = 'advance_payment', // Paid in advance.
  DEPOSIT = 'deposit', // This will be paid iff no show.
  PRE_BOOKING_PAYMENT_ON_WEB = 'pre_booking_payment_on_web', // Paid in advance.
}

export enum DISCOUNT_TYPE {
  PERCENTAGE = '%',
  TOTAL = '£',
}

export enum PRODUCT_CATEGORY_TYPE {
  ANTI_PARASITIC = 'anti_parasitic',
  CONSUMABLES = 'consumables',
  GENERAL_MEDICATION = 'general_medication',
  GROOMING_CARE = 'grooming_care',
  INJECTABLES = 'injectables',
  NUTRITION = 'nutrition',
  OTHER = 'other',
  PET_ACCESSORIES = 'pet_accessories',
  VACCINATION = 'vaccination',
}

export enum PAYMENT_METHOD {
  BANK_TRANSFER = 'bank_transfer',
  CARD = 'card',
  CASH = 'cash',
  CREDIT = 'credit',
  DOJO = 'dojo',
  INSURANCE = 'insurance',
  ONLINE = 'online',
  PAID_BY_STORE = 'paid_for_by_store',
  OTHER = 'other',
}

export enum PAYMENT_PROCESSOR {
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  DEBIT_CARD = 'debit_card',
  DOJO = 'dojo',
  PAYPAL = 'paypal',
  STRIPE = 'stripe',
  UNSPECIFIED = 'unspecified',
}

export enum PAYMENT_STATUS {
  ARCHIVED = 'archived',
  AUTHORIZED = 'authorized',
  CANCELLED = 'cancelled',
  CAPTURED = 'captured',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PENDING = 'pending',
  PROCESSING = 'processing',
  REFUNDED = 'refunded',
  VOIDED = 'voided',
}

export enum DOJO_TERMINALS_SESSION_TYPE {
  MATCHED_REFUND = 'MatchedRefund',
  SALE = 'Sale',
  UNLINKED_REFUND = 'UnlinkedRefund',
}

export enum DOJO_PAYMENT_STATUS {
  AUTHORIZED = 'Authorized',
  CANCEL_REQUESTED = 'CancelRequested',
  CANCELED = 'Canceled',
  CAPTURED = 'Captured',
  DECLINED = 'Declined',
  EXPIRED = 'Expired',
  INITIATE_REQUESTED = 'InitiateRequested',
  INITIATED = 'Initiated',
  REFUNDED = 'Refunded',
  REVERSED = 'Reversed',
  SIGNATURE_VERIFICATION_REJECTED = 'SignatureVerificationRejected',
  SIGNATURE_VERIFICATION_ACCEPTED = 'SignatureVerificationAccepted',
  SIGNATURE_VERIFICATION_REQUIRED = 'SignatureVerificationRequired',
}

export enum DOJO_NOTIFICATION_TYPE {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  INSERT_CARD = 'InsertCard',
  ENTER_PIN = 'EnterPin',
  PLEASE_WAIT = 'PleaseWait',
  PRESENT_CARD = 'PresentCard',
  PRESENT_ONLY_ONE_CARD = 'PresentOnlyOneCard',
  REMOVE_CARD = 'RemoveCard',
  CARD_UNSUPPORTED = 'CardUnsupported',
}

export enum APPOINTMENT_NOTIFICATION_REASON {
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  NO_SHOW = 'no_show',
  REQUESTED = 'requested',
  TIME = 'time',
}

export enum PET_TIMELINE_VIEW_ITEM_TYPE {
  APPOINTMENT = 'appointment',
  MEDICAL_RECORD = 'medical_record',
  NOTE = 'note',
  REMINDER = 'reminder',
  TREATMENT = 'treatment',
  PRESCRIPTION = 'prescription',
  INVOICE = 'invoice',
}

export enum EMPLOYEE_PERMISSION_LEVEL {
  ADMIN = 'admin',
  CORE = 'core',
  SUPPORT = 'support',
}

export enum PET_MEDICAL_RECORD_TYPE {
  DIAGNOSIS = 'diagnosis',
  PREVENTIVE = 'preventive',
}

export enum PET_DIAGNOSIS_CATEGORY {
  ALLERGY = 'allergy',
  ILLNESS = 'illness',
  INFECTION = 'infection',
  INJURY = 'injury',
  OTHER = 'other',
}

export enum PET_PREVENTIVE_CATEGORY {
  ANTI_PARASITIC = 'anti_parasitic',
  OTHER = 'other',
  VACCINATION = 'vaccination',
}

export enum PET_MEDICAL_RECORD_CATEGORY {
  ALLERGY = 'allergy',
  ILLNESS = 'illness',
  INFECTION = 'infection',
  INJURY = 'injury',
  ANTI_PARASITIC = 'anti_parasitic',
  VACCINATION = 'vaccination',
  OTHER = 'other',
}

export enum EMAIL_PROVIDERS {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

export enum DAYS_IN_ADVANCE {
  ONE_DAY = 1,
  THREE_DAYS = 3,
}

export enum PAYMENT_SETTING_ACCEPTED_PAYMENT_TYPE {
  DEPOSIT = 'DEPOSIT',
  PRE_PAYMENT = 'PRE_PAYMENT',
}

export enum PAYMENT_SETTING_ACCEPTED_PAYMENT_METHOD {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PAYMENT_TYPE {
  APPOINTMENT = 'appointment',
  INVOICE = 'invoice',
  APPOINTMENT_DEPOSIT = 'appointment_deposit',
}

export enum APPOINTMENT_CREATION_SOURCE {
  APP = 'app',
  PLUGIN = 'plugin',
  WORK = 'work',
  MIGRATION = 'migration',
}

export enum EMPLOYEE_ROLE {
  ADMINISTRATIVE = 'administrative',
  CARETAKER = 'caretaker',
  DIRECTOR = 'director',
  GROOMER = 'groomer',
  MANAGER = 'manager',
  NURSE = 'nurse',
  PCA = 'pca',
  RCVS_ADVANCED_PRACTITIONER = 'rcvs_advanced_practitioner',
  RCVS_SPECIALIST = 'rcvs_specialist',
  RECEPTIONIST = 'receptionist',
  STUDENT_NURSE = 'student_nurse',
  TRAINER = 'trainer',
  VETERINARIAN = 'veterinarian',
  VETERINARY_STAFF = 'veterinary_staff',
  VETERINARY_TECHNICIAN = 'veterinary_technician',
  WALKER = 'walker',
}

export enum PERMISSION_CATEGORY {
  ANALYTICS = 'analytics',
  ANALYTICS_LIMITED = 'analytics_limited',
  APPOINTMENTS_SETTINGS = 'appointments_settings',
  BOOKING_SETTINGS = 'booking_settings',
  BUSINESS_SETTINGS = 'business_settings',
  CHAT = 'chat',
  DISCOUNTS = 'discounts',
  INSURANCE = 'insurance',
  INTEGRATIONS_SETTINGS = 'integrations_settings',
  INVENTORY = 'inventory',
  LUPA_SETTINGS = 'lupa_settings',
  ROTA = 'rota',
  STAFF_SETTINGS = 'staff_settings',
}

export enum PRODUCT_MEDICAL_CATEGORY_TYPE {
  AVM_GSL = 'avm_gsl',
  NFA_VPS = 'nfa_vps',
  OTC = 'otc',
  POM = 'pom',
  POM_V = 'pom_v',
  POM_VPS = 'pom_vps',
  SAES = 'saes',
  OTHER = 'other',
}

export enum STORE_CATEGORY {
  ADOPTION = 'adoption',
  BOARDING = 'boarding',
  CEMETERY = 'cemetery',
  DAY_CARE = 'day_care',
  GROOMING = 'grooming',
  OTHER = 'other',
  PET_SHOP = 'pet_shop',
  SHELTER_RESCUE = 'shelter_rescue',
  SITTING = 'sitting',
  TRAINING = 'training',
  VET = 'vet',
  WALKING = 'walking',
}

export enum STORE_TEMPLATE_TYPE {
  NOTE = 'note',
  CONSENT = 'consent',
  EMAIL = 'email',
}

export enum APPOINTMENT_TYPE {
  ADMIN = 'admin',
  CONSULT = 'consult',
  DAYCARE = 'daycare',
  DENTAL = 'dental',
  DIAGNOSTICS = 'diagnostics',
  EUTHANASIA = 'euthanasia',
  GROOMING = 'grooming',
  GROUP_TRAINING = 'group_training',
  GROUP_WALKING = 'group_walking',
  HEALTH = 'health',
  HOSPITAL = 'hospital',
  IN_HOME = 'in_home',
  INDIVIDUAL_TRAINING = 'individual_training',
  INDIVIDUAL_WALKING = 'individual_walking',
  NURSE = 'nurse',
  OTHER = 'other',
  OVERNIGHT = 'overnight',
  SURGICAL = 'surgical',
  VACCINATION = 'vaccination',
}

export enum PERMISSION_LEVEL_VALUE {
  ALLOWED = 'allowed',
  BLOCKED = 'blocked',
}

export enum COMPANY_ACCOUNT_TYPE {
  FULL = 'full',
  PROFESSIONAL = 'professional',
}

export enum COMPANY_BUSINESS_TYPE {
  VET = 'vet',
  PETCARE = 'petcare',
}

export enum STORE_ONLINE_PAYMENT_METHOD {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PRODUCTS_SUPPLIER {
  MWI = 'mwi',
  NVS = 'nvs',
}

export enum INSURANCE_CLAIMS_STATUS {
  DECLINED = 'declined',
  DRAFT = 'draft',
  INITIATED = 'initiated',
  INVALID = 'invalid',
  NULLIFIED = 'nullified',
  PROCESSING = 'processing',
  READY_FOR_PROCESSING = 'ready_for_processing',
  SETTLED = 'settled',
}

export enum VET_ENVOY_ATTACHMENT_STATUS {
  DRAFT = 'draft',
  ID_GENERATED = 'id_generated',
  AVAILABLE_FOR_DOWNLOAD = 'available_for_download',
  DOWNLOAD_ATTEMPTED = 'download_attempted',
  DOWNLOADED = 'downloaded',
  ATTACHMENT_SERVICE_ENDED = 'attachment_service_ended',
}

export enum INSURANCE_FACILITATOR {
  VET_ENVOY = 'vetenvoy',
  LUPA = 'lupa',
}

export enum LUPA_ERROR_KEY {
  BAD_REQUEST = 'bad_request',
  CLIENT_EXISTS = 'client_exists',
  EXPIRED_TOKEN = 'expired_token',
  INVALID_AMOUNT = 'invalid_amount',
  INVALID_INVITE_LINK = 'invalid_invite_link',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  INVALID_CLAIM_DATA = 'invalid_claim_data',
  INVALID_TOKEN = 'invalid_token',
  NO_EMAIL_FOUND = 'no_email_found',
  NOT_FOUND = 'not_found',
  REMUS_EXPIRED = 'remus_expired',
  REMUS_INVALID_STRIPE_CUSTOMER = 'remus_invalid_stripe_customer',
  TOO_MANY_REQUESTS = 'too_many_requests',
  UNAUTHORIZED = 'unauthorized',
  UNSPECIFIED_LUPA_ERROR_KEY = 'unspecified_lupa_error_key',
  INTERNAL_ERROR = 'internal_error',
  OPENAI_ERROR = 'openai_error',
  DOJO_TERMINAL_NOT_AVAILABLE = 'dojo_terminal_not_available',
  SUPABASE_ERROR = 'supabase_error',
  HEALTH_PLAN_NAME_EXISTS = 'health_plan_name_exists',
  ACTIVE_SUBSCRIPTION_ALREADY_EXISTS = 'active_subscription_already_exists',
  NO_FILES_TO_UPLOAD = 'no_files_to_upload',
  AUTH_USER_HAS_NO_WORK_PROFILE = 'auth_user_has_no_work_profile',
  AUTH_USER_HAS_NO_WORK_PROFILE_BUT_HAS_REMUS_PROFILE = 'auth_user_has_no_work_profile_but_has_remus_profile',
  DRIZZLE = 'drizzle',
}

export enum REMUS_PRODUCT_TYPE {
  TRIAL = 'trial',
  PAY_AS_YOU_GO = 'pay_as_you_go',
  UNLIMITED = 'unlimited',
  UNLIMITED_YEARLY = 'unlimited_yearly',
}

export enum REMUS_CONSULTATION_PROCESSING_STATUS {
  STARTING = 'starting',
  TRANSCRIBING = 'transcribing',
  LLM = 'llm',
  FINISHED_ERROR = 'finished_error',
  FINISHED_SUCCESS = 'finished_success',
}

export enum REMUS_TRANSCRIPTION_MODEL {
  OPENAI_WHISPER_1 = 'openai-whisper-1',
  ASSEMBLYAI_BEST = 'assemblyai-best',
}

export enum APPOINTMENT_PAYMENT_TYPE {
  PAYMENT = 'payment', // Standard payment for an appointment.
  PRE_PAYMENT = 'pre_payment', // Pre-payment for an appointment. TODO: Do we really need this???
  BOOKING = 'booking', // Payment for a new appointment. Could be a deposit.
}

export enum HISTORY_CHANGE_ACTION {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  UPSERT = 'UPSERT',
}

export enum STRIPE_PAYMENT_TYPE {
  PAYMENT = 'payment', // Standard payment for an appointment.
  PRE_PAYMENT = 'pre_payment', // Pre-payment for an appointment. TODO: Do we really need this???
  BOOKING = 'booking', // Payment for a new appointment. Could be a deposit.
  INVOICE_PAYMENT = 'invoice_payment', // Standard payment for an invoice.
}

export enum PET_SEX {
  MALE = 'Male',
  FEMALE = 'Female',
  UNKNOWN = 'Unknown',
}

export enum DIAGNOSTIC_ORDER_SOURCE {
  ORDERED_IN_LUPA = 'ORDERED_IN_LUPA',
  ORDERED_OUTSIDE_OF_LUPA = 'ORDERED_OUTSIDE_OF_LUPA',
  RESULTS_WITHOUT_ORDER = 'RESULTS_WITHOUT_ORDER',
}

export enum DIAGNOSTIC_ORDER_STATUS {
  CREATING = 'CREATING',
  CREATED = 'CREATED',
  ORDERED = 'ORDERED',
  WAITING_FOR_SAMPLE = 'WAITING_FOR_SAMPLE',
  SAMPLE_RECEIVED = 'SAMPLE_RECEIVED',
  RUNNING = 'RUNNING',
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum DIAGNOSTIC_RESULT_STATUS {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum DIAGNOSTIC_RESULT_MODALITY {
  REFLAB = 'REFLAB',
  INHOUSE = 'INHOUSE',
  DIGITAL = 'DIGITAL',
  OTHER = 'OTHER',
}

export enum DIAGNOSTIC_PROVIDER_TYPE {
  VETSCAN_STAGING = 'VETSCAN_STAGING',
  VETSCAN_PROD = 'VETSCAN_PROD',
  IDEXX_VETCONNECTPLUS_STAGING = 'IDEXX_VETCONNECTPLUS_STAGING',
  IDEXX_VETCONNECTPLUS_PROD = 'IDEXX_VETCONNECTPLUS_PROD',
}

export enum DIAGNOSTIC_DEVICE_TYPE {
  IDEXX_IVLS = 'IDEXX_IVLS',
}

export enum PRODUCTS_SUPPLIER_CLIENT_TYPE {
  NVS_STAGING = 'NVS_STAGING',
  NVS_PROD = 'NVS_PROD',
  MWI = 'MWI',
}

export enum PREFERRED_CONTACT_METHOD_FOR_CLAIMS {
  PHONE = 'Phone',
  EMAIL = 'Email',
  TEXT = 'Text',
  LETTER = 'Letter',
}

export enum BACKGROUND_TASK_RUN_STATUS {
  ASSIGNED = 'ASSIGNED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum USER_HAS_PERMISSION_STATUS_TO_SEND_EMAIL {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  UNKNOWN_REASON = 'UNKNOWN_REASON',
}

export enum EMPLOYEES_WORKING_HOURS_TYPE {
  REGULAR = 'regular',
  CUSTOM = 'custom',
}

export enum EMPLOYEES_WORKING_HOURS_STATUS {
  AVAILABLE = 'available',
  VACATION = 'vacation',
  SICK = 'sick',
  UNAVAILABLE = 'unavailable',
  REGULAR = 'regular',
  CLOSED = 'closed',
  CUSTOM = 'custom',
}

export enum REMUS_PROFILE_PERMISSION {
  ADMIN = 'admin',
  STAFF = 'staff',
}

export enum FILE_MIME_TYPE {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  BMP = 'image/bmp',
  WEBP = 'image/webp',
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  MP3 = 'audio/mpeg',
  WAV = 'audio/wav',
  OGG = 'audio/ogg',
  TXT = 'text/plain',
  HTML = 'text/html',
  CSS = 'text/css',
  JSON = 'application/json',
  XML = 'application/xml',
  ZIP = 'application/zip',
  GZIP = 'application/gzip',
  CSV = 'text/csv',
  TSV = 'text/tab-separated-values',
}

export enum BILLING_ITEM_TYPE {
  PRODUCT = 'product',
  SERVICE = 'service',
}

export enum CLIENT_COMMUNICATION_METHOD {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export enum CLIENT_COMMUNICATION_REASON {
  PET_SCHEDULE_REMINDER = 'PET_SCHEDULE_REMINDER',
  BOOKING_REMINDER = 'BOOKING_REMINDER',
  BOOKING_UDPATE = 'BOOKING_UDPATE',
  BOOKING_CONFIRMATION = 'BOOKING_CONFMRIATION', // note the typo
  BOOKING_REQUESTED = 'BOOKING_REQUESTED',
  CHAT_MESSAGE_NOTIFICATION = 'CHAT_MESSAGE_NOTIFICATION',
  CORRECTION_NOTIFICATION = 'CORRECTION_NOTIFICATION',
  PRESCRIPTION_READY_FOR_PICKUP = 'PRESCRIPTION_READY_FOR_PICKUP',
  MANUAL_APPOINTMENT_EMAIL = 'MANUAL_APPOINTMENT_EMAIL',
  MANUAL_INVOICE_REMINDER = 'MANUAL_INVOICE_REMINDER',
}

export enum CLIENT_COMMUNICATION_STATUS {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  FAILED = 'FAILED',
}

export enum PRESCRIPTION_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum HEALTH_PLAN_ALLOWANCE_TYPE {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
}

export enum HEALTH_PLAN_BILLING_PERIOD_TYPE {
  ANNUALLY = 'annually',
  MONTHLY = 'monthly',
}

export enum HEALTH_PLAN_ALLOWANCE_ITEM_TYPE {
  PRODUCT_CATEGORY = 'product_category',
  SERVICE_CATEGORY = 'service_category',
  INDIVIDUAL_PRODUCT = 'individual_product',
  INDIVIDUAL_SERVICE = 'individual_service',
}

export enum HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE {
  LIFETIME = 'lifetime', // e.g. once per pet for lifetime of pet. https://www.notion.so/lupapets/MD-request-HEALTH-PLAN-149a151d856d804fbf3cfdd5e954790b
  ANNUALLY = 'annually',
  MONTHLY = 'monthly',
}

export enum HEALTH_PLAN_SUBSCRIBER_TYPE {
  CLIENT = 'client',
  PET = 'pet',
}

export enum HEALTH_PLAN_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export enum HEALTH_PLAN_ALLOWANCE_STATUS {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum HEALTH_PLAN_SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}

export enum ONLINE_BOOKING_EMPLOYEE_STATUS {
  ALLOW = 'allow',
  AUTO_ASSIGN = 'auto_assign',
  SPECIFIC = 'specific',
}

export enum PET_SCHEDULE_TYPE {
  VACCINATION = 'VACCINATION',
  ANTI_PARASITIC = 'ANTI_PARASITIC',
  OTHER_PREVENTIVE = 'OTHER_PREVENTIVE',
  PRESCRIPTION_REFILL = 'PRESCRIPTION_REFILL',
}

export enum PET_SCHEDULE_CATEGORY {
  TREATMENT = 'TREATMENT',
  PRESCRIPTION_REFILL = 'PRESCRIPTION_REFILL',
}

export enum PET_SCHEDULE_RECURRENCE_TYPE {
  MANUAL = 'MANUAL',
}

export enum PET_SCHEDULE_EVENT_STATUS {
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  DELETED = 'DELETED',
}

export enum EMAIL_SEND_FAILURE_REASON {
  NO_EMAIL_ADDRESS_FOR_CLIENT = 'NO_EMAIL_ADDRESS_FOR_CLIENT',
  STORE_IS_DRAFT = 'STORE_IS_DRAFT',
}

export enum STORE_INVOICE_STATUS {
  DRAFT = 'draft', // Can edit billing items.
  COMPLETED = 'completed', // Cannot edit billing items.
  ARCHIVED = 'archived', // Deleted.
}

export enum STORE_INVOICE_PAYMENT_STATUS {
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  UNPAID = 'unpaid',
}

export enum ATTACHMENT_STATUS {
  DRAFT = 'draft',
  AVAILABLE = 'available',
  FAILED = 'failed',
}

export enum INTERNAL_DATA_LOOKUP_TYPE {
  COMPANY = 'COMPANY',
  STORE = 'STORE',
  CLIENT = 'CLIENT',
  PET = 'PET',
  APPOINTMENT = 'APPOINTMENT',
  INVOICE = 'INVOICE',
  PRESCRIPTION = 'PRESCRIPTION',
  CLAIM = 'CLAIM',
  HEALTH_PLAN = 'HEALTH_PLAN',
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
  BILLING_SERVICE = 'BILLING_SERVICE',
  BILLING_PRODUCT = 'BILLING_PRODUCT',
}

export enum ANALYTICS_TABLE_DATA_SOURCE {
  PAYMENTS = 'payments',
  INVOICES = 'invoices',
  BILLING_ITEMS = 'billing_items',
  PRODUCTS = 'products',
  CLIENTS = 'clients',
  PETS = 'pets',
}

export enum DATA_ACCESS_LEVEL {
  EMPLOYEE = 'employee',
  STORE = 'store',
  COMPANY = 'company',
}

export enum PET_SPECIES {
  // Common pets
  DOG = 'Dog',
  CAT = 'Cat',
  CHINCHILLA = 'Chinchilla',
  FERRET = 'Ferret',
  GERBIL = 'Gerbil',
  GUINEA_PIG = 'Guinea Pig',
  HAMSTER = 'Hamster',
  HEDGEHOG = 'Hedgehog',
  MOUSE = 'Mouse',
  RABBIT = 'Rabbit',
  RAT = 'Rat',
  SUGAR_GLIDER = 'Sugar Glider',
  SMALL_MAMMAL_OTHER = 'Small Mammals - Other',
  LAGAMORPH = 'Lagamorph',
  RODENTS = 'Rodents',

  // Birds
  BUDGIE = 'Budgie',
  CANARY = 'Canary',
  CHICKEN = 'Chicken',
  PARROT = 'Parrot',
  BIRD_OTHER = 'Avian - Other',
  BIRDS_WILD = 'Birds - Wild',
  BIRD_GENERAL = 'Bird',
  AVIAN_GENERAL = 'Avian',

  // Reptiles & Amphibians
  FROG = 'Frog',
  LIZARD = 'Lizard',
  SNAKE = 'Snake',
  TORTOISE = 'Tortoise',
  TURTLE = 'Turtle',
  REPTILE_AMPHIBIAN_OTHER = 'Reptile & Amphibian - Other',
  REPTILE = 'Reptile',

  // Livestock & Farm
  BOVINE = 'Bovine',
  CAPRINE = 'Caprine',
  HORSE = 'Horse',
  OVINE = 'Ovine',
  PORCINE = 'Porcine',
  LIVESTOCK_FARM_OTHER = 'Livestock & Farm - Other',
  DONKEY = 'Donkey',
  MULE = 'Mule',

  // Fish
  FRESHWATER_FISH = 'Freshwater Fish',
  SALTWATER_FISH = 'Saltwater Fish',
  FISH_GENERAL = 'Fish',

  // Other categories
  TARANTULA = 'Tarantula',
  EXOTIC = 'Exotic Pets - Other',
  EXOTIC_GENERAL = 'Exotic',
  WILDLIFE = 'Wildlife',
  WILD_ANIMAL = 'Wild Animal',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}

export enum CREDENTIAL_TYPE {
  PASSWORD = 'password',
  API_KEY = 'api_key',
  NONE = 'none',
}

export enum WEIGHT_UNIT {
  KG = 'kg',
  G = 'g',
  MG = 'mg',
  UMG = 'umg',
  ML = 'ml',
  L = 'l',
}

export enum PRODUCT_APPLICATION {
  INJECTION = 'injection',
  INHALATION = 'inhalation',
  INTRAVENOUS = 'intravenous',
  ORAL = 'oral',
  TOPICAL = 'topical',
  OTHER = 'other',
}

export enum PRODUCT_ACTIVE_INGREDIENT {
  OTHER = 'other',
  AMOXICILLIN = 'amoxicillin',
  ATENOLOL = 'atenolol',
  CEPHALEXIN = 'cephalexin',
  GABAPENTIN = 'gabapentin',
  CIMICOXIB = 'cimicoxib',
  BENAZEPRIL = 'benazepril',
  ACEPROMAZINE_MALEATE = 'acepromazine_maleate',
  ACETYLCYSTEINE = 'acetylcysteine',
  ACETYLSALICYLIC_ACID = 'acetylsalicylic_acid',
  ALBENDAZOLE = 'albendazole',
  ALTRENOGEST = 'altrenogest',
  AMITRAZ = 'amitraz',
  AMOXICILLIN_SODIUM = 'amoxicillin_sodium',
  AMOXICILLIN_TRIHYDRATE = 'amoxicillin_trihydrate',
  AMPICILLIN_SODIUM = 'ampicillin_sodium',
  AMPICILLIN_TRIHYDRATE = 'ampicillin_trihydrate',
  AMPROLIUM_HYDROCHLORIDE = 'amprolium_hydrochloride',
  APOMORPHINE_HYDROCHLORIDE = 'apomorphine_hydrochloride',
  APRAMYCIN_SULFATE = 'apramycin_sulfate',
  ATIPAMEZOLE_HYDROCHLORIDE = 'atipamezole_hydrochloride',
  AZAPERONE = 'azaperone',
  BACITRACIN_ZINC = 'bacitracin_zinc',
  BENAZEPRIL_HYDROCHLORIDE = 'benazepril_hydrochloride',
  BENZYLPENICILLIN_POTASSIUM = 'benzylpenicillin_potassium',
  BENZYLPENICILLIN_PROCAINE = 'benzylpenicillin_procaine',
  BISMUTH_SUBNITRATE = 'bismuth_subnitrate',
  BORIC_ACID = 'boric_acid',
  BROMHEXINE_HYDROCHLORIDE = 'bromhexine_hydrochloride',
  BUPRENORPHINE_HYDROCHLORIDE = 'buprenorphine_hydrochloride',
  BUSERELIN = 'buserelin',
  BUTAFOSFAN = 'butafosfan',
  BUTORPHANOL_TARTRATE = 'butorphanol_tartrate',
  CABERGOLINE = 'cabergoline',
  CARBETOCIN = 'carbetocin',
  CARPROFEN = 'carprofen',
  CEFALEXIN = 'cefalexin',
  CEFQUINOME = 'cefquinome',
  CEFTIOFUR = 'ceftiofur',
  CHLORAMPHENICOL = 'chloramphenicol',
  CHLORHEXIDINE = 'chlorhexidine',
  CHLORPHENAMINE = 'chlorphenamine',
  CHLORTETRACYCLINE = 'chlortetracycline',
  CICLOSPORIN = 'ciclosporin',
  CLENBUTEROL = 'clenbuterol',
  CLINDAMYCIN = 'clindamycin',
  CLOPROSTENOL = 'cloprostenol',
  CLOSANTEL = 'closantel',
  COLISTIN = 'colistin',
  CYANOCOBALAMIN = 'cyanocobalamin',
  DELTAMETHRIN = 'deltamethrin',
  DENAVERINE = 'denaverine',
  DETOMIDINE = 'detomidine',
  DEXAMETHASONE = 'dexamethasone',
  DEXMEDETOMIDINE = 'dexmedetomidine',
  DIAZEPAM = 'diazepam',
  DORAMECTIN = 'doramectin',
  DOXYCYCLINE = 'doxycycline',
  ENFLICOXIB = 'enflicoxib',
  ENROFLOXACIN = 'enrofloxacin',
  EPHEDRINE = 'ephedrine',
  EPRINOMECTIN = 'eprinomectin',
  FIROCOXIB = 'firocoxib',
  FLORFENICOL = 'florfenicol',
  FLUBENDAZOLE = 'flubendazole',
  FLUMETHRIN = 'flumethrin',
  FLUNIXIN = 'flunixin',
  FLUORESCEIN = 'fluorescein',
  FLUOXETINE = 'fluoxetine',
  FORMIC_ACID = 'formic_acid',
  FUROSEMIDE = 'furosemide',
  GAMITHROMYCIN = 'gamithromycin',
  GENTAMICIN = 'gentamicin',
  GLEPTOFERRON = 'gleptoferron',
  GONADORELIN = 'gonadorelin',
  GRAPIPRANT = 'grapiprant',
  HALOFUGINONE = 'halofuginone',
  HYDROCORTISONE = 'hydrocortisone',
  HYOSCINE = 'hyoscine',
  ICHTHAMMOL = 'ichthammol',
  IMIDACLOPRID = 'imidacloprid',
  IODINE = 'iodine',
  ISOFLURANE = 'isoflurane',
  ITRACONAZOLE = 'itraconazole',
  IVERMECTIN = 'ivermectin',
  KETAMINE = 'ketamine',
  KETOPROFEN = 'ketoprofen',
  LEVAMISOLE = 'levamisole',
  LEVOTHYROXINE = 'levothyroxine',
  LIDOCAINE = 'lidocaine',
  LINCOMYCIN = 'lincomycin',
  LOTILANER = 'lotilaner',
  LUFENURON = 'lufenuron',
  MARBOFLOXACIN = 'marbofloxacin',
  MAROPITANT = 'maropitant',
  MEBENDAZOLE = 'mebendazole',
  MEDETOMIDINE = 'medetomidine',
  MELOXICAM = 'meloxicam',
  MENBUTONE = 'menbutone',
  METAMIZOLE = 'metamizole',
  METHADONE = 'methadone',
  METOCLOPRAMIDE = 'metoclopramide',
  METRONIDAZOLE = 'metronidazole',
  MICONAZOLE = 'miconazole',
  MILBEMYCIN_OXIME = 'milbemycin_oxime',
  MONEPANTEL = 'monepantel',
  MONENSIN = 'monensin',
  MOXIDECTIN = 'moxidectin',
  NANDROLONE = 'nandrolone',
  NEOMYCIN = 'neomycin',
  NITENPYRAM = 'nitenpyram',
  OMEPRAZOLE = 'omeprazole',
  OXALIC_ACID = 'oxalic_acid',
  OXYTETRACYCLINE = 'oxytetracycline',
  OXYTOCIN = 'oxytocin',
  PARACETAMOL = 'paracetamol',
  PAROMOMYCIN = 'paromomycin',
  PEFORELIN = 'peforelin',
  PENETHAMATE = 'penethamate',
  PENTOBARBITAL = 'pentobarbital',
  PERGOLIDE = 'pergolide',
  PERMETHRIN = 'permethrin',
  PHENOBARBITAL = 'phenobarbital',
  PHENYLBUTAZONE = 'phenylbutazone',
  PHOXIM = 'phoxim',
  PIMOBENDAN = 'pimobendan',
  POTASSIUM_BROMIDE = 'potassium_bromide',
  PRADOFLOXACIN = 'pradofloxacin',
  PRAZIQUANTEL = 'praziquantel',
  PREDNISOLONE = 'prednisolone',
  PROGESTERONE = 'progesterone',
  PROPENTOFYLLINE = 'propentofylline',
  PROPOFOL = 'propofol',
  PROPOXUR = 'propoxur',
  PYRANTEL = 'pyrantel',
  PYRIPROLE = 'pyriprole',
  ROBENACOXIB = 'robenacoxib',
  ROPINIROLE = 'ropinirole',
  SODIUM_SALICYLATE = 'sodium_salicylate',
  SPIRAMYCIN = 'spiramycin',
  SULFACLOZINE = 'sulfaclozine',
  SULFADIMETHOXINE = 'sulfadimethoxine',
  SULFADIMIDINE = 'sulfadimidine',
  SULFADOXINE = 'sulfadoxine',
  SUXIBUZONE = 'suxibuzone',
  TETRACAINE = 'tetracaine',
  TETRACYCLINE = 'tetracycline',
  THIAMAZOLE = 'thiamazole',
  TIAMULIN = 'tiamulin',
  TILMICOSIN = 'tilmicosin',
  TOLDIMFOS = 'toldimfos',
  TOLFENAMIC_ACID = 'tolfenamic_acid',
  TOLTRAZURIL = 'toltrazuril',
  TRAMADOL = 'tramadol',
  TRICLABENDAZOLE = 'triclabendazole',
  TRILOSTANE = 'trilostane',
  TRIMETHOPRIM = 'trimethoprim',
  TULATHROMYCIN = 'tulathromycin',
  TYLOSIN = 'tylosin',
  VATINOXAN = 'vatinoxan',
  XYLAZINE = 'xylazine',
}

export enum CREDIT_NOTE_ITEM_TYPE {
  PRODUCT = 'product',
  SERVICE = 'service',
}

export enum CREDIT_NOTE_REASON {
  // TODO: replace with actual reasons
  ACCIDENTAL_INVOICE = 'ACCIDENTAL_INVOICE',
  JUST_FEELING_NICE_TODAY = 'JUST_FEELING_NICE_TODAY',
}

export enum CREDIT_NOTE_STATUS {
  DRAFT = 'draft',
  ISSUED = 'issued',
  VOID = 'void',
}

export enum CREDIT_NOTE_ITEM_CREDIT_TYPE {
  NONE = 'NONE',
  FIXED_AMOUNT = 'fixed_amount',
  PERCENTAGE = 'percentage',
  QUANTITY = 'quantity',
}

export enum CREDIT_NOTE_ITEM_DISCOUNT_TYPE {
  LINE_ITEM_FIXED_AMOUNT = 'line_item_fixed_amount',
  LINE_ITEM_PERCENTAGE = 'line_item_percentage',
  GLOBAL_PERCENTAGE = 'global_percentage',
}

export enum CREDIT_NOTE_VALIDATION_ERROR_TYPE {
  CREDITED_TOTAL_EXCEEDS_INVOICED_TOTAL = 'CREDITED_TOTAL_EXCEEDS_INVOICED_TOTAL',
  LINE_ITEM_QUANTITY_EXCEEDS_INVOICED_QUANTITY = 'LINE_ITEM_QUANTITY_EXCEEDS_INVOICED_QUANTITY',
  LINE_ITEM_TOTAL_EXCEEDS_INVOICED_LINE_ITEM_TOTAL = 'LINE_ITEM_TOTAL_EXCEEDS_INVOICED_LINE_ITEM_TOTAL',
}

export enum CLIENT_BALANCE_TRANSACTION_ACTION {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum RESOURCE_PERMISSION {
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  INVENTORY_PRODUCT_READ = 'INVENTORY_PRODUCT_READ',
  INVENTORY_PRODUCT_EDIT = 'INVENTORY_PRODUCT_EDIT',
  INVENTORY_PRODUCT_DELETE = 'INVENTORY_PRODUCT_DELETE',
  INVENTORY_PRODUCT_NAME_EDIT = 'INVENTORY_PRODUCT_NAME_EDIT',
  INVENTORY_PRODUCT_PRICE_EDIT = 'INVENTORY_PRODUCT_PRICE_EDIT',
  SERVICE_READ = 'SERVICE_READ',
  SERVICE_NAME_EDIT = 'SERVICE_NAME_EDIT',
}
